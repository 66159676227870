import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { Answer } from "src/app/models/answer";
import { FormResponse } from "src/app/models/form-response.model";
import { SubscriptionManager } from "src/app/models/subscription-manager";
import { DatabaseService } from "src/app/shared/services/database.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-pdf",
  templateUrl: "./pdf.component.html",
  styleUrls: ["./pdf.component.css"],
})
export class PdfComponent implements OnInit, OnDestroy {
  html = "";
  formName = "";
  subscriptions = SubscriptionManager.newSubCollection();
  sniperForm: FormResponse;
  id: string;
  downloadLink: string;
  devMode = environment.dev;
  terms: string;
  loading = false;
  error: string;
  answerGroups: { answers: Answer[]; groupNumber: number }[] = [];

  constructor(
    public database: DatabaseService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.route.paramMap
      .pipe(
        switchMap((paramMap) => {
          this.id = paramMap.get("id");
          const collection = paramMap.get("form");
          if (this.id && collection) {
            this.router.navigate([`/forms/${collection}`], {
              queryParams: { id: this.id },
            });
          }
          this.downloadLink = `${environment.app.functionsURL}/sniperPDF?collection=${collection}&id=${this.id}`;
          return this.database.getForm(collection, this.id);
        })
      )
      .subscribe(
        (formResponse) => {
          this.sniperForm = formResponse;
          console.log({ formResponse });
          if (!this.sniperForm) {
            this.loading = false;
            this.error = "Document Not Found.";
            return;
          }
          this.terms = this.sniperForm.contractTerms;
          this.createAnswerGroups();
          this.loading = false;
        },
        (err) => {
          console.error("Documnet not found/unavailable", err);
          this.error = "Document Not Found.";
          this.loading = false;
        }
      );
  }

  ngOnDestroy() {
    SubscriptionManager.unsubscribe(this.subscriptions);
  }

  print() {
    window.print();
  }

  createAnswerGroups() {
    const answers = this.sniperForm.answers ?? [];
    answers.forEach((answer) => {
      const group = this.answerGroups.find(
        (g) => g.groupNumber === answer.groupNumber
      );
      if (group) {
        group.answers.push(answer);
      } else {
        this.answerGroups.push({
          answers: [answer],
          groupNumber: answer.groupNumber,
        });
      }
    });
  }
}
