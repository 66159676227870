import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbCarouselModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { CyberguardGuardingComponent } from "./pages/commercial-services/cyberguard-guarding/cyberguard-guarding.component";
import { GeneralComponent } from "./pages/commercial-services/general/general.component";
import { SecurityAssessmentsComponent } from "./pages/commercial-services/security-assessments/security-assessments.component";
import { EmergencyGuardingComponent } from "./pages/emergency-guarding/emergency-guarding.component";
import { HomeComponent } from "./pages/home/home.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { SharedModule } from "./shared/shared.module";

import { AngularFireModule } from "@angular/fire/compat";
import {
  AngularFirestoreModule,
  SETTINGS as FIRESTORE_SETTINGS,
} from "@angular/fire/compat/firestore";
import { AngularFireFunctionsModule } from "@angular/fire/compat/functions";

import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { environment } from "src/environments/environment";
import { GeneralEnquiryComponent } from "./forms/general-enquiry/general-enquiry.component";
import { PrintTemplateComponent } from "./forms/print-template/print-template.component";
import { SideNavComponent } from "./navbar/side-nav/side-nav.component";
import { AboutComponent } from "./pages/about-pages/about/about.component";
import { AreasComponent } from "./pages/about-pages/areas/areas.component";
import { CareersComponent } from "./pages/about-pages/careers/careers.component";
import { ComplianceComponent } from "./pages/about-pages/compliance/compliance.component";
import { PolicePartnershipComponent } from "./pages/about-pages/police-partnership/police-partnership.component";
import { PoliciesComponent } from "./pages/about-pages/policies/policies.component";
import { RateOurServiceComponent } from "./pages/about-pages/rate-our-service/rate-our-service.component";
import { RecruitmentComponent } from "./pages/about-pages/recruitment/recruitment.component";
import { SocialComponent } from "./pages/about-pages/social/social.component";
import { UctMbaComponent } from "./pages/about-pages/uct-mba/uct-mba.component";
import { WhyChooseUsComponent } from "./pages/about-pages/why-choose-us/why-choose-us.component";
import { CommercialServicesModule } from "./pages/commercial-services/commercial-services.module";
import { ContactUsComponent } from "./pages/contact-us/contact-us.component";
import { LuxuryArmouredVehiclesComponent } from "./pages/how-can-we-assist-you/luxury-armoured-vehicles/luxury-armoured-vehicles.component";
import { InternsComponent } from "./pages/interns/interns.component";
import { AcceptComponent } from "./pages/payment/accept/accept.component";
import { CheckoutComponent } from "./pages/payment/checkout/checkout.component";
import { DeclineComponent } from "./pages/payment/decline/decline.component";
import { RedirectComponent } from "./pages/payment/redirect/redirect.component";
import { PdfComponent } from "./pages/pdf/pdf.component";
import { PersonalProtectionComponent } from "./pages/personal-protection/personal-protection.component";
import { ResidentialServicesModule } from "./pages/residential-services/residential-services.module";
import { TacticalSecurityPackagesComponent } from "./pages/tactical-security-packages/tactical-security-packages.component";
import { TestComponent } from "./pages/test/test.component";
import { PromotionsComponent } from "./promotions/promotions.component";
import {
  USE_EMULATOR as USE_AUTH_EMULATOR,
  AngularFireAuthModule,
} from "@angular/fire/compat/auth";

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    PageNotFoundComponent,
    HomeComponent,
    SecurityAssessmentsComponent,
    GeneralComponent,
    CyberguardGuardingComponent,
    EmergencyGuardingComponent,
    SideNavComponent,
    TestComponent,
    AboutComponent,
    PoliciesComponent,
    RecruitmentComponent,
    ContactUsComponent,
    SocialComponent,
    TacticalSecurityPackagesComponent,
    UctMbaComponent,
    PdfComponent,
    AcceptComponent,
    DeclineComponent,
    RedirectComponent,
    PersonalProtectionComponent,
    CheckoutComponent,
    WhyChooseUsComponent,
    ComplianceComponent,
    PolicePartnershipComponent,
    AreasComponent,
    LuxuryArmouredVehiclesComponent,
    CareersComponent,
    RateOurServiceComponent,
    PrintTemplateComponent,
    PromotionsComponent,
    GeneralEnquiryComponent,
    InternsComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    SharedModule,
    NgbModule,
    NgbCarouselModule,
    BrowserAnimationsModule,
    ResidentialServicesModule,
    CommercialServicesModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: FIRESTORE_SETTINGS,
      useValue: environment.dev ? { host: "localhost:8080", ssl: false } : {},
    },
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.dev ? ["http://localhost:9099", 9099] : undefined,
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-ZA" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
