import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { EmergencyGuardingComponent } from "./pages/emergency-guarding/emergency-guarding.component";
import { CyberguardGuardingComponent } from "./pages/commercial-services/cyberguard-guarding/cyberguard-guarding.component";
import { CyberguardCommunityComponent } from "./pages/residential-services/cyberguard-community/cyberguard-community.component";
import { AboutComponent } from "./pages/about-pages/about/about.component";
import { PoliciesComponent } from "./pages/about-pages/policies/policies.component";
import { RecruitmentComponent } from "./pages/about-pages/recruitment/recruitment.component";
import { ContactUsComponent } from "./pages/contact-us/contact-us.component";
import { SocialComponent } from "./pages/about-pages/social/social.component";
import { TacticalSecurityPackagesComponent } from "./pages/tactical-security-packages/tactical-security-packages.component";
import { UctMbaComponent } from "./pages/about-pages/uct-mba/uct-mba.component";
import { PdfComponent } from "./pages/pdf/pdf.component";
import { AcceptComponent } from "./pages/payment/accept/accept.component";
import { DeclineComponent } from "./pages/payment/decline/decline.component";
import { RedirectComponent } from "./pages/payment/redirect/redirect.component";
import { PersonalProtectionComponent } from "./pages/personal-protection/personal-protection.component";
import { WhyChooseUsComponent } from "./pages/about-pages/why-choose-us/why-choose-us.component";
import { ComplianceComponent } from "./pages/about-pages/compliance/compliance.component";
import { PolicePartnershipComponent } from "./pages/about-pages/police-partnership/police-partnership.component";
import { AreasComponent } from "./pages/about-pages/areas/areas.component";
import { LuxuryArmouredVehiclesComponent } from "./pages/how-can-we-assist-you/luxury-armoured-vehicles/luxury-armoured-vehicles.component";
import { CareersComponent } from "./pages/about-pages/careers/careers.component";
import { RateOurServiceComponent } from "./pages/about-pages/rate-our-service/rate-our-service.component";
import { GeneralEnquiryComponent } from "./forms/general-enquiry/general-enquiry.component";
import { InternsComponent } from "./pages/interns/interns.component";

const routes: Routes = [
  { path: "home", component: HomeComponent },
  // { path: 'residential', loadChildren: () => import('./pages/residential-services/residential-services.module').then(m => m.ResidentialServicesModule) },
  // { path: 'commercial', loadChildren: () => import('./pages/commercial-services/commercial-services.module').then(m => m.CommercialServicesModule) },

  { path: "contact-us", component: ContactUsComponent },

  {
    path: "pay",
    children: [
      { path: "accept", component: AcceptComponent },
      { path: "decline", component: DeclineComponent },
      { path: "redirect", component: RedirectComponent },
    ],
  },

  { path: "emergency-guarding", component: EmergencyGuardingComponent },
  { path: "cyberguard-guarding", component: CyberguardGuardingComponent },
  { path: "cyberguard-community", component: CyberguardCommunityComponent },

  {
    path: "luxury-armoured-vehicles",
    component: LuxuryArmouredVehiclesComponent,
  },

  { path: "personal-protection", component: PersonalProtectionComponent },

  {
    path: "security-packages",
    component: TacticalSecurityPackagesComponent,
    data: { version: "2" },
  },

  { path: "general-enquiries", component: GeneralEnquiryComponent },

  { path: "pdf", component: PdfComponent },
  { path: "pdf/:form/:id", component: PdfComponent },

  { path: "about", component: AboutComponent },
  { path: "why-choose-us", component: WhyChooseUsComponent },
  { path: "compliance", component: ComplianceComponent },
  { path: "policies", component: PoliciesComponent },
  { path: "recruitment-policy", component: RecruitmentComponent },
  { path: "social", component: SocialComponent },
  { path: "uct-mba", component: UctMbaComponent },
  { path: "police-partnership", component: PolicePartnershipComponent },
  { path: "areas", component: AreasComponent },
  { path: "careers", component: CareersComponent },
  { path: "rate-our-service", component: RateOurServiceComponent },
  { path: "interns", component: InternsComponent },
  {
    path: "forms",
    loadChildren: () =>
      import("./forms/forms.module").then((m) => m.FormsModule),
  },
  {
    path: "sign-in",
    loadComponent: () =>
      import("./auth/sign in/sign-in.component").then((m) => m.SignInComponent),
  },
  {
    path: "promotions/:id",
    pathMatch: "full",
    redirectTo: "forms/technical-booking",
  }, //temporary redirection for qr code. Remove when unneeded
  { path: "", redirectTo: "home", pathMatch: "full" },

  { path: "**", component: PageNotFoundComponent }, // Wildcard route for a 404 page
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
